// export const BASE_URL = process.env.apiUrl;
// export const BASE_URL = 'https://admin.dev.phxpro.ru/';
export const BASE_URL = 'https://admin.dev.phxpro.ru/';

export const BASE_GRAPHQL_URL = BASE_URL + (process.env.faker === '1' ? 'faker/' : '') + 'graphql';
// export const BASE_GRAPHQL_URL = 'http://localhost:8080/graphql';

export const LOGIN_URL = '/auth/login';


